import { graphql } from 'gatsby';
import React from 'react';

import Banner from '../../components/Banner';
import Pagination from '../../components/pagination';
import Container from '../../components/Container';
import Layout from '../../components/Layout';
import ProductCardGrid from '../../components/ProductCardGrid';
import Seo from '../../components/seo';

import * as styles from './category.module.css';

const CategoryPage = ({ pageContext, data: { category, productsList: { nodes: products } } }) => {
  const { numOfPages, currentPageIndex } = pageContext;

  return (
    <Layout>
      <div className={styles.root}>
        <Seo
          title={category.name}
          description={category?.description || products?.[0]?.short_description}
          banner={products?.[0]?.images?.[0]?.src || ""}
          pathname={`/category/${category.slug}/`}
        />
        <Banner
          maxWidth={'650px'}
          name={category.name}
          subtitle={category?.description || ''}
        />
        <Container size={'large'} spacing={'min'}>
          <div className={styles.productContainer}>
            <ProductCardGrid data={products} />
          </div>
          <div className={styles.loadMoreContainer}>
            <span>{currentPageIndex + 1} de {numOfPages}</span>
            <Pagination
              numOfPages={numOfPages}
              currentPageIndex={currentPageIndex || 0}
              mainPath={`/shop`}
            />
          </div>
        </Container>
      </div>
    </Layout>
  );
};


export const pageQuery = graphql`
  query ($id: Int!, $limit: Int!, $skip: Int!) {
    productsList: allWcProducts(
      filter: {
        category_ids: {
          eq: $id
        }
      }
      limit: $limit,
      skip: $skip
    ) {
      totalCount
      nodes {
        wordpress_id
        slug
        name
        regular_price
        price
        short_description
        images {
          src
          width
          height
          is_resized
        }
      }
    }
    category: wcProductsCategories (
      term_id: {
        eq: $id 
      }
    ) {
      id
      name
      slug
      taxonomy
      count
      description
    }
  }
`;

export default CategoryPage;
