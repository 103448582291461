// extracted by mini-css-extract-plugin
export var bannerContainer = "category-module--bannerContainer--AqO-b";
export var breadcrumbContainer = "category-module--breadcrumbContainer--vR-Gi";
export var chipsContainer = "category-module--chipsContainer--nS6nZ";
export var controllerContainer = "category-module--controllerContainer--B4Zz9";
export var iconContainer = "category-module--iconContainer--iWGKh";
export var itemCount = "category-module--itemCount--khdj6";
export var loadMoreContainer = "category-module--loadMoreContainer--xJKNq";
export var metaContainer = "category-module--metaContainer--4mGCm";
export var mobileItemCount = "category-module--mobileItemCount--4Er9X";
export var productContainer = "category-module--productContainer---iYdh";
export var root = "category-module--root--VJiVD";
export var sortContainer = "category-module--sortContainer--iXkt2";
export var subtitleStyle = "category-module--subtitleStyle--FJJY-";